import React from "react"

import CenterColumn from "../../components/Bulma/CenterColumn"
import { Button } from "../../components/Button"
import { Text, Image } from "../../components/Core"

const Exparel = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <a className="asird__link" href={post.button.href} target="_blank">
      <CenterColumn
        className="home-asird"
        leftColWidth={2}
        rightColWidth={2}
        centerColumn={
          <div
            className="column asird-grid"
            data-aos="fade-up"
            data-aos-duration="1200">
            <Image publicId={post.imageId} />
            <div>
              <Text text={post.blurb} />
              {/* <div className="button-row">
              <Button
                contained
                href={post.button.href}
                buttonText={post.button.buttonText}
                external
              />
            </div> */}
            </div>
          </div>
        }
      />
    </a>
  )
}

export default Exparel
