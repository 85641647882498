import React from "react"

import CenterColumn from "../../components/Bulma/CenterColumn"
import { Text, Image } from "../../components/Core"
import { Button } from "../../components/Button"

const Exparel = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <CenterColumn
      className="home-exparel"
      leftColWidth={1}
      rightColWidth={1}
      centerColumn={
        <div
          className="column home-exparel__grid"
          data-aos="fade-up"
          data-aos-duration="1200">
          <Image publicId={post.imageId} />
          {/* <div className="home-exparel__divider" /> */}
          <Text as="p" text={post.blurb} />
          <Button
            destination="internal"
            appearance="white"
            buttonText={post.button.buttonText}
            href={post.button.href}
          />
        </div>
      }
    />
  )
}

export default Exparel
